body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Archivo", sans-serif !important;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  /* font-family: "Archivo", sans-serif !important; */
  margin: 0;
}

h1 {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: capitalize;
}

h2 {
  font-size: 1.6rem;
  font-weight: 300;
}

h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

h4 {
  font-size: .85rem;
  font-weight: 800;
}

h5 {
  font-size: .8rem;
  font-weight: 800;
}

p{
  font-size: 1rem;
}

@media (min-width: 1600px) {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 1.8rem;
  }
  
  h2 {
    font-size: 1.6rem;
  }
  
  h3 {
    font-size: 1.3rem;
  }
  
  h4 {
    font-size: 1rem;
  }
  
  h5 {
    font-size: .8rem;
  }
}
